.App {
  text-align: center;
  font-size: 4vw;
  color: white;
}

.greeting {
  display: inline-flex;
  font-size: 7vw;
  font-family: fantasy;
}

.blue {
  color: #005bbc;
}

.yellow {
  color: #ffd600;
}

video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}
